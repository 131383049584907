<template>
  <b-modal
    :title="title"
    v-model="showModal"
    size="xmd"
    modal-class="modal-primary"
    title-tag="h3"
    hide-footer
    @hidden="$emit('hidden')"
    :no-close-on-backdrop="true"
    centered
  >
    <validation-observer ref="SendOrReturnForm">
      <b-row>
        <b-col md="6">
          <b-input-group prepend="Client" class="mt-1">
            <b-form-input readonly v-model="client.lead_name"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col md="6">
          <b-input-group prepend="ACCOUNT" class="mt-1">
            <b-form-input readonly v-model="client.account"></b-form-input>
          </b-input-group>
        </b-col>

        <b-col cols="6" v-if="title === 'SEND TO CONNECTION' && moduleId === 4">
          <b-form-group label=" Select a reason" class="mt-2">
            <div style="display: grid; grid-template-columns: 12fr 0.5fr">
              <validation-provider
                v-slot="{ errors }"
                name="charge"
                rules="required"
              >
                <b-form-select
                  v-model="reasonSelected"
                  :options="reasons"
                  :state="errors[0] ? false : null"
                />
              </validation-provider>
            </div>
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            class="mt-2"
            id="fieldset-1"
            label="Description"
            label-for="input-1"
          >
            <validation-provider
              name="Comment"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-textarea
                class="mB-3"
                id="textarea"
                v-model="comment"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
                :state="errors[0] ? false : null"
              ></b-form-textarea>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12" class="text-right my-1">
          <b-button
            v-b-modal.modal-action
            v-b-tooltip.hover.top="'Send'"
            class="button"
            variant="success"
            @click="submit()"
          >
            <feather-icon icon="SendIcon" style="margin-right: 5px" size="15" />
            <span> Send </span>
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>
<script>
import StatusChangeService from "@/views/crm/views/status-change/service/status-change.service";

export default {
  name: "ModalSendOrReturnClient",
  props: {
    title: {
      type: String,
      default: "",
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    tab: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      selected: null,
      comment: "",
      reasonSelected: "",
      reasons: [
        { value: "STATUS ECONOMY", text: "STATUS ECONOMY" },
        { value: "RESULTS", text: "RESULTS" },
        { value: "OTHERS", text: "OTHERS" },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-send-or-return-client", false);
    },
    submit: async function () {
      try {
        let in_connection =
          this.moduleId != 4 && this.tab == 2 ? "ADMINISTRATION" : "CONNECTION";
        let status_connection =
          this.moduleId != 4 && this.tab == 2 ? "TRANSITION" : "HOLD";
        let params;
        let successMessage;
        if (this.title === "RETURN CLIENT") {
          params = {
            client_id: this.client.client_id,
            client_account_id: this.client.account_id,
            in_connection: "ORIGIN",
            status_connection: "REJECTED",
            reason_connection: "",
            advisor_id: "",
            comment: this.comment,
            user_id: this.userId,
          };
          successMessage = "Client returned successfully";
        } else {
          params = {
            client_id: this.client.client_id,
            client_account_id: this.client.account_id,
            in_connection: in_connection,
            status_connection: status_connection,
            reason_connection: this.reasonSelected,
            advisor_id: this.client.advisor_id,
            comment: this.comment,
            user_id: this.userId,
          };
          successMessage = "Client sent to connection successfully";
        }

        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          this.addPreloader();
          const { status } = await StatusChangeService.sendOrReturnClient(
            params
          );
          if (status === 200) this.showSuccessSwal(successMessage);
          this.closeModal();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
