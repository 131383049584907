<template>
  <div>
    <filter-slot
      :filter="filterStatus"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="reloadTable()"
    >
      <status-change-table
        slot="table"
        :my-provider="myProvider"
        :fields="fields"
        ref="refTable"
        :current-page="paginate.currentPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :paginate="paginate"
        :module-id="moduleId"
        :role-id="currentUser.role_id"
        @show-tracking-list="showModalTrackingList($event)"
        @show-send-or-return-client="showModalSendOrReturnClient($event)"
      ></status-change-table>
    </filter-slot>

    <ModalTrackingList
      v-if="showTrackingModal"
      @close-tracking-list="closeModalTrackingList($event)"
      :tracking-list="trackingList"
    />

    <ModalSendOrReturnClient
      v-if="showSendOrReturnClient"
      :title="modalTitle"
      @close-send-or-return-client="closeModalSendOrReturnClient($event)"
      @hidden="showSendOrReturnClient = false"
      :client="client"
      :tab="2"
      :moduleId="moduleId"
      :userId="userId"
    >
    </ModalSendOrReturnClient>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatusChangeService from "@/views/crm/views/status-change/service/status-change.service";
import ModalTrackingList from "@/views/crm/views/status-change/components/ModalTrackingList.vue";
import FilterSlot from "@/views/crm/views/status-change/components/slots/FilterSlot.vue";
import filters from "../data/filters.status-change.data";
import fields from "../data/fields.status-change.data";
import StatusChangeTable from "@/views/crm/views/status-change/components/StatusChangeTable.vue";

import ModalSendOrReturnClient from "@/views/crm/views/status-change/components/ModalSendOrReturnClient.vue";

export default {
  name: "HoldTab",
  components: {
    ModalTrackingList,
    FilterSlot,
    "status-change-table": StatusChangeTable,
    ModalSendOrReturnClient,
  },
  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "",
      sortDesc: true,
      arrayColumns: fields,
      startPage: null,
      toPage: null,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Status Change...",
        model: "",
      },
      filter: filters,
      showTrackingModal: false,
      items: [],
      trackingList: [],
      showSendOrReturnClient: false,
      modalTitle: "",
      client: {},
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId: function () {
      return this.$route.matched[0].meta.module;
    },
    userId: function () {
      return this.currentUser.user_id;
    },
    fields: function () {
      this.arrayColumns.push({
        key: "actions",
        label: "Actions",
        sortable: false,
        visible: true,
      });
      return this.arrayColumns.filter((column) => column.visible);
    },
    filterStatus: function () {
      return this.filter;
    },
  },

  created() {
    this.setOptionsOnFilters();
  },
  methods: {
    showModalSendOrReturnClient(object) {
      this.modalTitle = object.type;
      this.client = object.client;
      this.showSendOrReturnClient = true;
    },

    setOptionsOnFilters() {
      this.filter[2].options = [
        { value: "", label: "ALL" },
        { value: "HOLD", label: "CONNECTION" },
        { value: "TRANSITION", label: "SENT" },
        { value: "REJECTED", label: "REJECTED" },
        { value: "RECYCLE", label: "RECYCLE" },
        { value: "RECOVERED", label: "RECOVERED" },
      ];
    },
    reloadTable() {
      this.$refs["refTable"].reloadTable();
    },

    closeModalTrackingList(event) {
      this.showTrackingModal = false;
    },
    closeModalSendOrReturnClient(event) {
      this.showSendOrReturnClient = false;
      this.reloadTable();
    },

    showModalTrackingList: async function (accountId) {
      const { data } = await StatusChangeService.getTrackingListByClientId({
        id: accountId,
      });
      this.trackingList = data;
      this.showTrackingModal = true;
    },
    myProvider: async function (ctx) {
      try {
        let params = {
          advisor_code: "",
          per_page: ctx.perPage || 10,
          page: ctx.currentPage || 1,
          change_status: 2,
          client_connection_state: this.filter[2].model || "",
          client_mobile: "",
          client_state: "",
          date_from: this.filter[0].model || "",
          date_to: this.filter[1].model || "",
          in_connection: "",
          lead_name: this.filterPrincipal.model || "",
          role_id: "",
        };
        const { data, current_page, per_page, total, from, to } =
          await StatusChangeService.getClientsConnection(params);
        this.items = data;
        this.startPage = from;
        this.paginate.currentPage = current_page;
        this.paginate.perPage = per_page;
        this.totalRows = total;
        this.toPage = to;
        return this.items || [];
      } catch (error) {
        this.items = [];
      }
    },
  },
};
</script>